.content-basic__content.products-list-content {
    padding: 0;
}
.products-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.products-list__category-tree {
    flex: 0 0 250px;
    border-right: 1px solid $color-grey-3;

    .products-list__category-tree__main-category-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $color-grey-3;

        &:last-child {
            border-bottom: none;
        }
    }

    .products-list__category-tree__main-category {
        width: 100%;
        display: flex;
        min-height: 4rem;
        justify-content: flex-start;
        align-items: stretch;
        cursor: pointer;

        .products-list__category-tree__switch {
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-2;
            flex: 0 0 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .products-list__category-tree__label {
            flex: 1 1 10rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem 0;
            font-size: 1.2rem;

            .light {
                padding-left: 0.5rem;
                color: $color-grey-2;
            }
        }
    }

    .products-list__category-tree__child-category {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        min-height: 4rem;
        border-top: 1px solid $color-grey-3;
        background-color: $color-background;
        cursor: pointer;

        .products-list__category-tree__label {
            display: flex;
            flex: 1 1 10rem;
            padding-left: 3rem;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.2rem;

            .light {
                padding-left: 0.5rem;
                color: $color-grey-2;
            }
        }

        .products-list__category-tree__icon {
            flex: 0 0 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-2;
        }

        &.isSelected {
            background-color: $color-primary;
            color: $color-primary-c;

            .light {
                color: $color-primary-c;
            }

            .products-list__category-tree__icon {
                color: $color-primary-c;
            }
        }
    }
}

.products-list__product-list {
    flex: 1 0 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // padding: 1rem;

    .products-list__product {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // border: 1px solid $color-grey-3;
        // border-bottom: none;
        min-height: 4.1rem;
        padding: 0.5rem;
        font-size: 1.2rem;

        &:last-child {
            // border-bottom: 1px solid $color-grey-3;
        }

        border-bottom: 1px solid $color-grey-3;

        .products-list__product-id {
            color: $color-grey-2;
            flex: 0 0 auto;
            padding: 0 0.5rem 0 0;
        }

        .products-list__product-name {
            flex: 1 0 200px;
        }

        .products-list__product-type {
            flex: 0 0 150px;
            text-align: center;
        }

        .products-list__product-visible {
            flex: 0 0 100px;
            text-align: center;
        }

        .products-list__product-number {
            text-align: center;
            flex: 0 0 70px;
        }
    }

    .products-list__info {
        width: 100%;
        font-size: 1.4rem;
        color: $color-grey-1;
        font-weight: bold;
        text-align: center;
        padding: 3rem 0;
    }
}
