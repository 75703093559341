@import '../../scss/variables';

._nav {
    $nav-height: 6rem;

    z-index: 50;
    width: 100%;
    min-height: $nav-height;
    max-height: $nav-height;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-elem-background;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 1rem 0.5rem $color-elem-shadow;

    ._nav__left {
        order: 1;
        flex: 0 0 3*$nav-height;
        height: $nav-height;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 2rem;
        // position: absolute;

        ._nav__left__logo {
            height: 4rem;
            width: auto;;
        }
    }

    ._nav__middle {
        order: 3;
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: $dim-breakpoint-sm) {
            flex: 1 0 30rem;
            width: auto;
            order: 2;
            padding-right: 12rem;
            flex-direction: row;
            justify-content: center;
        }

        a, button {
            width: 100%;
            height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;
            text-transform: uppercase;
            text-decoration: none;
            color: $color-primary;
            font-weight: bold;
            background-color: transparent;
            border: none;
            cursor: pointer;

            @media (min-width: $dim-breakpoint-sm) {
                width: auto;
                padding: 0 2rem;
                height: 100%;
            }
        }

        ul {
            list-style: none;
            width: 100%;
            padding: 0 0 1rem 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 1 0 30rem;
                flex-direction: row;
                justify-content: center;
                height: $nav-height;
                padding: 0 1rem;
            }

            li {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem;
                height: 5rem;
                color: $color-primary;
                @include ff-title();
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1.4rem;
                cursor: pointer;

                @media (min-width: $dim-breakpoint-sm) {
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    ._nav__right {
        order: 2;
        flex: 0 0 $nav-height;
        height: $nav-height;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: $dim-breakpoint-sm) {
            display: none;
        }

        ._nav__toggle {
            width: $nav-height;
            height: $nav-height;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            ._nav__toggle__bar {
                width: 2.5rem;
                height: 0.2rem;
                border-radius: 0.2rem;
                position: relative;

                &::before,
                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background-color: $color-grey-1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform-origin: center center;
                    transition: all 0.2s ease-in-out;
                }

                &::before {
                    transform: translate(0, 0.4rem) rotate(0deg);
                }

                &::after {
                    transform: translate(0, -0.4rem) rotate(0deg);
                }
            }
        }
    }

    &._nav--is-open {
        max-height: 100vh;

        ._nav__right {
            ._nav__toggle {
                ._nav__toggle__bar {
                    &::after {
                        transform: translate(0, 0) rotate(45deg);
                    }
                    &::before {
                        transform: translate(0, 0) rotate(-45deg);
                    }
                }
            }
        }
    }
}
