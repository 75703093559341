@import '../../scss/variables';

.field-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .field-wrapper__label {
        @include ff-title();
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        color: $color-grey-2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 2rem 0.2rem 0rem;
        order: 1;
        transition: all 0.2s ease-in-out;
    }

    .field-wrapper__field {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        order: 2;
    }

    .field-wrapper__error {
        color: $color-red;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 2rem 1rem;
        order: 3;
        font-size: 1.2rem;
    }

    &.field-wrapper--error {
        .field-wrapper__label {
            color: $color-red;
        }
        .field-wrapper__field {
            .field {
                border-color: $color-red;
            }
        }
    }
}
