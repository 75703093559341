@import '../../../../../scss/variables';

._magazine-order-list-parent {
    &__wrapper {
        width: 100%;
        display: flex;
        min-height: 4rem;
        padding: 0.5rem 0;
        border: 1px solid $color-grey-4;
        align-items: center;
        font-size: 1.2rem;
        text-align: center;

        &:not(:last-child) {
            border-bottom: none;
        }
    }

    &__open-icon {
        @include ff-icon();
        width: 4rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color: $color-grey-2;
        cursor: pointer;
    }
    &__id {
        width: 10%;
        font-weight: bold;
    }

    &__contractor {
        width: 20%;
        text-align: left;
        padding: 0 0.5rem;

        .small {
            font-size: 1rem;
            color: $color-grey-1;
            font-weight: bold;
        }
    }

    &__destination {
        text-align: left;
        width: 17%;
        padding: 0 0.5rem;

        .small {
            font-size: 1rem;
            color: $color-grey-1;
            font-weight: bold;
        }
    }

    &__created {
        width: 10%;
    }

    &__shipment-date {
        width: 10%;
    }

    &__status {
        width: 10%;
        font-size: 1.1rem;
        font-weight: bold;
        color: $color-grey-2;
        text-transform: uppercase;
    }

    &__products {
        width: 10%;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        flex: 1 0 8%;

        .button-light {
            margin-right: 1rem;
        }
    }
}
