@import '../../../../scss/_variables.scss';

.contract-order-list {
    &__element {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    &__order-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        flex: 0 0 40rem;
    }

    &__contract-id {
        display: flex;
        padding: 1rem;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;

        flex: 1 0 100%;
    }

    &__company {
        flex: 1 0 10rem;
        display: flex;
        flex-direction: column;
        height: 3rem;
        align-content: flex-start;
        flex-wrap: wrap;

        &-name {
            font-size: 1.2rem;
        }
    }

    &__destination {
        flex: 1 0 10rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        height: 6rem;
        flex-wrap: wrap;
        width: 100%;

        &-label {
            font-size: 1.2rem;
        }
    }

    &__icon {
        @include ff-icon();
        font-size: 1.6rem;
        flex: 1 0 100%;
        color: $color-grey-2;
        padding: 0 0.5rem 0 1rem;
    }

    &__order-created {
        display: flex;
        align-items: center;

        &-icon {
            @include ff-icon();
            padding: 0 0.5rem 0 0;
            font-size: 1.8rem;
            color: $color-grey-2;
        }

        &-date {
            font-size: 1.2rem;
        }
    }

    &__order-status {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-grey-2;
    }
}
