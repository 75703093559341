@import '../scss/variables';

.data-source-loader {
    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 10;
    }

    &__title {
        @include ff-title();
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.6rem;
        color: $color-primary;
    }

    &__label {
        font-size: 1.4rem;
        padding: 1rem 0;
        max-width: 50rem;
        text-align: center;
    }
}
