.form-file-upload__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    .form-file-upload__input-wrapper {
        flex: 1 0 50%;
        position: relative;
        display: flex;
        align-items: stretch
    }

    .form-file-upload__input-placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid $color-grey-1;
        border-radius: 0.3rem;
        padding: 0.2rem 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2rem;
        cursor: pointer;

        .filename {
            flex: 1 0 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .material-icons {
            font-size: 1.8rem;
            margin-right: 1rem;
            color: $color-grey-2;
            flex: 0 0 2rem;
        }
    }

    .form-file-upload__input {
        width: 100%;
        height: 5rem;
        position: relative;
        opacity: 0;
    }

}