@import "../../../../scss/variables";

._magazine-stock-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__product {
        display: flex;
        flex-direction: column;

        &-content {
            display: flex;
            align-items: center;
            padding: 2rem;
            width: 100%;
            cursor: pointer;
            border-bottom: 1px solid $color-grey-3;
            font-size: 1.2rem;

            &-title {
                flex: 1 0 10rem;
            }

            &-ean {
                flex: 0 0 10rem;
                text-align: right;
            }

            &-count {
                flex: 0 0 10rem;
                text-align: center;
            }

            &-arrow {
                @include ff-icon;
                font-size: 2rem;
                color: $color-grey-2;
                flex: 0 0 4rem;
                text-align: center;
            }
        }

        &-locations {
            display: flex;
            flex-direction: column;
            // border-bottom: 1px solid $color-grey-3;
            background-color: $color-grey-5;
            border-left: 5px solid $color-grey-3;
            border-right: 5px solid $color-grey-3;
        }

        &-location {
            display: flex;
            flex-direction: column;

            &-content {
                display: flex;
                justify-content: flex-end;
                font-size: 1.2rem;
                border-bottom: 1px solid $color-grey-3;

                &-title {
                    flex: 1 0 10rem;
                    text-align: right;
                    padding: 2rem 0;
                }

                &-count {
                    flex: 0 0 10rem;
                    text-align: center;
                    padding: 2rem 1rem;
                }

                &-arrow-space,
                &-arrow {
                    flex: 0 0 5.5rem;
                    color: $color-grey-2;
                    text-align: center;
                    @include ff-icon;
                    padding: 2rem 2rem 2rem 0.5rem;
                    font-size: 2rem;
                    cursor: pointer;
                }
            }

            &-count {
                display: flex;
                background-color: $color-grey-4;
                border-bottom: 1px solid $color-grey-3;
                border-left: 5px solid $color-grey-1;
                border-right: 5px solid $color-grey-1;
                font-size: 1.2rem;
                padding: 2rem 5rem 2rem 2rem;

                &-date {
                    flex: 1 0 10rem;
                    text-align: right;
                }

                &-count {
                    flex: 0 0 10rem;
                    text-align: center;
                }
            }
        }
    }
}
