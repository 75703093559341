@import '../../scss/variables';

.select-with-button {
    $dim-button-width: 70px;

    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    background-color: $color-elem-background;

    &::before {
        content: 'expand_more';
        width: 2rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: $dim-button-width;
        top: 0;
        font-family: 'Material Icons';
        z-index: 1;
    }

    .select-with-button__select {
        flex: 1 0 100px;
        height: 3rem;
        border: 1px solid $color-grey-1;
        border-radius: 0.3rem 0 0 0.3rem;
        padding: 0 1rem;
        -webkit-appearance: none;
        border-right: none;
        background-color: transparent;
        z-index: 2;
        cursor: pointer;
    }

    .select-with-button__button {
        flex: 0 0 $dim-button-width;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-grey-1;
        border-radius: 0 0.3rem 0.3rem 0;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-grey-2;
        cursor: pointer;
    }
}
