.details-tree__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
}

.details-tree__main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid $color-grey-3;

    &:not(:last-child) {
        border-bottom: none;
    }

    .details-tree__main-content {
        width: 100%;
        min-height: 4rem;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        cursor: pointer;

        .details-tree__main-expand-icon {
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-2;
            flex: 0 0 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .details-tree__main-label {
            flex: 1 1 10rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.4rem;

            .small-label {
                padding-left: 1rem;
                color: $color-grey-2;
            }
        }

        .details-tree__main-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.details-tree__child {
    display: flex;
    width: 100%;
    flex-direction: column;

    .details-tree__child-content {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        min-height: 5rem;
        border-top: 1px solid $color-grey-3;
        background-color: $color-background;
        cursor: pointer;
        padding: 0 0 0 2rem;

        .details-tree__child-expand-icon {
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-2;
            flex: 0 0 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .details-tree__child-label {
            flex: 1 1 10rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.4rem;

            .small-label {
                padding-left: 1rem;
                color: $color-grey-2;
            }
        }
    }
    .details-tree__child-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        font-size: 1.2rem;

        .details-tree__child-row-title {
            font-weight: bold;
             color: $color-grey-2;
        }

        .details-tree__child-row-value {
            padding: 0 1rem 0 1rem;
        }
    }
}

.details-tree__element {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background-color: $color-background-2;
    border-top: 1px solid $color-grey-3;
    min-height: 5rem;
    padding: 0 0 0 6rem;

    .details-tree__element-label {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
    }

    .details-tree__element-ean {
        padding-left: 1rem;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;
        color: $color-grey-1;
        flex: 1 0 10rem;
    }

    .details-tree__element-buttons {
        padding-right: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
