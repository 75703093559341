@import '../scss/variables';

.new-modal-wrapper {
    &__wrapper {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color-grey-4, 0.8);
        z-index: 101;
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        max-height: 90vh;
        overflow: auto;
        background-color: $color-elem-background;
        z-index: 110;
        border-radius: 0.3rem;
        @include custom-shadow();
    }

    &__header {
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-grey-4;

        &-title {
            @include ff-title();
            font-size: 1.6rem;
            text-transform: uppercase;
            color: $color-primary;
            font-weight: 500;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-height: 70vh;
        min-height: 20rem;
        overflow: auto;
        padding: 2rem 0;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-top: 1px solid $color-grey-4;
    }
}
