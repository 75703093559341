._search-input {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 5rem;

    ._search-input__input {
        flex: 1 0 10rem;
        border: 1px solid $color-primary;
        border-right: none;
        border-radius: 0.5rem 0 0 0.5rem;
        padding: 0 1rem;
        font-size: 1.4rem;
    }

    ._search-input-button {
        border-radius: 0 0.5rem 0.5rem 0;
    }
}

._search-cta {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: $color-grey-2;
    text-transform: uppercase;
}

._search-result__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border:1px solid $color-grey-3;
    padding: 1rem;
    
    &:not(:last-child) {
        border-bottom: none;
    }

    &:nth-child(2){
        border-radius: 0.5rem 0.5rem 0 0;
    }
    &:last-child{
        border-radius: 0 0 0.5rem 0.5rem;
    }
    
    ._search-result__info {
        flex: 1 0 10rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        ._search-result__name {
            font-size: 1.4rem;
            // font-weight: bold;
        }

        ._search-result__ean {
            font-size: 1.4rem;
            font-weight: bold;
            padding-left: 1rem;
            color: $color-grey-1;
            flex: 1 0 10rem;
        }
        ._search-result__type {
            font-size: 1.2rem;
            color: $color-grey-2;
            padding-right: 3rem;
        }
        ._search-result__category {
            font-size: 1.2rem;
            color: $color-grey-2;
        }
    }

    ._search-result__button {
        flex: 0 0 10rem;
        display: flex;
        justify-content: flex-end;
        align-items: center
    }
}