@import '../../scss/variables';

@keyframes loader-mask-spinner-animation {
    0% {
        transform: scale(0);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

._loader-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;

    ._loader-mask__background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color:$color-elem-background;
        opacity: 0.9;
        z-index: 1;
    }

    ._loader-mask__content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        flex-direction: column;

        ._loader-mask__spinner {
            width: 10rem;
            height: 10rem;
            position: relative;

            ._loader-mask__spinner__element {
                $animation-speed: 3s;

                transform: scale(0);
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 0.7rem $color-primary solid;
                animation-name: loader-mask-spinner-animation;
                animation-duration: $animation-speed;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;

                &:nth-child(1){
                    animation-delay: 0s;
                }
                &:nth-child(2){
                    animation-delay: $animation-speed * (1/3);
                }
                &:nth-child(3){
                    animation-delay: $animation-speed * (2/3);
                }
            }
        }

        ._loader-mask__label {
            text-align: center;
            @include ff-title();
            font-weight: 500;
            font-size: 1.84rem;
            color: $color-primary;
        }
    }
}