@import "../../../../scss/variables";

.start-screen-add {
    &__search-input {
        margin-top: -2rem;
        padding: 2rem 1rem 2rem;
        border-bottom: 1px solid $color-grey-3;
        position: sticky;
        top: -2rem;
        background-color: $color-elem-background;
        z-index: 1;
    }

    &__search-results {
        padding: 0 2rem;
    }
}

.start-screen-add-item {
    padding: 1rem;
    border: 1px solid $color-grey-3;
    display: flex;
    justify-content: space-between;

    &__name {
        font-size: 1.2rem;
        padding-right: 1rem;
    }

    &:not(:first-child) {
        border-top: none;
    }

    &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
    }
}
