@import '../../scss/variables';

.text-field-l {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .text-field-l__row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        .text-field-l__lang {
            flex: 0 0 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            color: $color-grey-2;
            border: 1px solid $color-grey-1;
        }

        .text-field-l__input {
            flex: 1 0 10rem;
            height: 3.6rem;
            border: 1px solid $color-grey-1;
            margin: 0;
            padding: 0;
            border-left: none;
            padding: 0 1rem;
            font-size: 1.3rem;
            color: $color-primary;
            font-weight: bold;
        }

        .text-field-l__textarea {
            flex: 1 0 10rem;
            height: 20rem;
            border: 1px solid $color-grey-1;
            margin: 0;
            padding: 0;
            border-left: none;
            padding: 0 1rem;
            font-size: 1.3rem;
            color: $color-primary;
            font-weight: bold;
            resize: none;
        }

        &:nth-child(2) {
            .text-field-l__lang {
                border-radius: 0.3rem 0 0 0;
            }
            .text-field-l__input,
            .text-field-l__textarea {
                border-radius: 0 0.3rem 0 0;
            }
        }

        &:nth-last-child(2) {
            .text-field-l__lang {
                border-radius: 0 0 0 0.3rem;
            }
            .text-field-l__input,
            .text-field-l__textarea {
                border-radius: 0 0 0.3rem 0;
            }
        }

        &:not(:nth-last-child(2)) {
            .text-field-l__lang {
                border-bottom: none;
            }
            .text-field-l__input,
            .text-field-l__textarea {
                border-bottom: none;
            }
        }
    }

    .text-field__label-button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text-field__label {
        flex: 1 0 50%;
        padding: 1rem 0 0.5rem;
        font-size: 1.3rem;
        color: $color-grey-2;
        text-transform: uppercase;
        font-weight: bold;
    }

    .text-field__delete-button {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color-grey-2;

        .material-icons {
            font-size: 1.8rem;
        }
    }

    .text-field-l__error {
        height: 1.5rem;
        font-size: 1.1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $color-red;
    }

    &.text-field-l--is-error {
        .text-field__label {
            color: $color-red;
        }
    }
}

._text-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    ._text-field__label {
        @include ff-title();
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        color: $color-grey-2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 2rem 0.2rem 0rem;
        order: 1;
        transition: all 0.2s ease-in-out;
    }

    ._text-field__input {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $color-grey-1;
        padding: 1rem 2rem;
        font-size: 1.6rem;
        color: $color-primary;
        order: 2;
        transition: all 0.2s ease-in-out;
        border-radius: 0.3rem;

        &:focus {
            border-color: $color-primary;

            & + ._text-field__label {
                color: $color-primary;
            }
        }
    }

    ._text-field__error {
        color: $color-red;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 2rem 1rem;
        order: 3;
        font-size: 1.2rem;
    }

    &._text-field--is-error {
        ._text-field__input {
            border-color: $color-red;
        }
    }
}
