@import "../../scss/variables";

.overlay-new {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    background-color: rgba($color-elem-background, 0.8);
    z-index: 2;
    justify-content: flex-start;
    align-items: center;

    &__title {
        text-align: center;
        padding: 0.5rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-primary;
    }

    &__text {
        padding: 0.5rem;
    }

    &__children {
        padding: 0.5rem;
    }

    &__loader {
        padding: 0.5rem;
    }
}

.loader-small {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;

    &::before,
    &:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
    }

    color: $color-primary;
    font-size: 6px;
    margin: 2em;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
    }

    &:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    &:after {
        left: 3.5em;
    }
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
