@import "../../../../../../scss/variables";

._add-product-modal {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__searchbar {
        width: 100%;
        border-bottom: 1px solid $color-grey-3;
        padding: 0 2rem 2rem;
        display: flex;
        align-items: stretch;

        &-input {
            flex: 1 0 10rem;
            font-size: 1.6rem;
            height: 4rem;
            padding: 0 2rem;
            border: 1px solid $color-primary;
            border-radius: 0.5rem 0 0 0.5rem;
        }

        &-button {
            @include ff-text;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: bold;
            color: $color-primary-c;
            border-radius: 0 0.5rem 0.5rem 0;
            background-color: $color-primary;
            border: 1px solid $color-primary;
            padding: 0 2rem;
            cursor: pointer;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }

    &__product {
        display: flex;
        border: 1px solid $color-grey-1;
        padding: 1rem;
        align-items: center;

        &:not(:last-child) {
            border-bottom: none;
        }

        &-content {
            flex: 1 0 10rem;
            display: flex;
            flex-direction: column;
            padding-right: 2rem;

            &-title {
                font-size: 1.4rem;
            }
            &-ean {
                padding: 0.5rem 0;
                font-size: 1.4rem;
            }
        }
    }
}
