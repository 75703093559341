@import '../../scss/variables';

._checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    cursor: pointer;

    ._checkbox__box {
        width: 3rem;
        height: 3rem;
        border: 1px solid $color-grey-2;
        border-radius: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: white;
        transition: all 0.2s ease-in-out;

        i {
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }

        &._checkbox__box--is-checked {
            background-color: $color-primary;

            i {
                opacity: 1;
            }
        }
    }

    ._checkbox__label {
        padding: 0.5rem 1rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        @include ff-title();
        font-weight: 500;
        color: $color-grey-2;
    }
}
