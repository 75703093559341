@import "../../../../scss/variables";

.category-reorder-item {
    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__element {
        display: flex;
        align-items: stretch;
        width: 100%;
        padding: 0 1rem;
        border-bottom: 1px solid $color-grey-5;
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        @include ff-icon;
        border: none;
        background-color: $color-elem-background;
        width: 3rem;
        height: 4rem;
        text-align: center;
        font-size: 1.8rem;
        color: $color-grey-2;
        cursor: pointer;
    }

    &__title {
        flex: 1 0 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__children {
        display: flex;
        flex-direction: column;
        border-left: 1rem solid $color-grey-1;
    }
}
