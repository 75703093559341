@import '../../../../../scss/variables';

._magazine-order-edit-product-row {
    &__wrapper {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-4;
        }
    }

    &__product-info {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 0 1rem 1rem 2rem;

        &-name {
            font-size: 1.2rem;
            font-weight: bold;
            color: $color-primary;
        }

        &-ean {
            font-size: 1.2rem;font-weight: bold;
            color: $color-grey-2;

        }

        &-category {
            font-size: 1.1rem;
        }
    }

    &__product-quantiy {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        padding: 0 2rem 1rem 0;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: $color-grey-2;
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;

        &-quantity {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: stretch;

            &-row {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0.5rem 2rem;
                border-top: 1px solid $color-grey-4;
                background-color: $color-grey-5;

                input {
                    height: 2rem;
                    border: 1px solid $color-primary;
                    margin: 0 0.5rem;
                }

                &-date {
                    width: 15rem;
                }
                &-value {
                    width: 7rem;
                }
            }
        }
    }

    &__add-more {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid $color-grey-4;
        padding: 1rem 2rem 2rem 2rem;
    }
}
