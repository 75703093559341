@import '../scss/variables';

@keyframes new-loader-animation {
    0% {
        transform: scale(0);
        opacity: 0.7;
    }

    40% {
        transform: scale(1);
        opacity: 1;
    }

    80% {
        transform: scale(0);
        opacity: 0.7;
    }

    100% {
        transform: scale(0);
    }
}

.new-loader {
    &__wrapper {
        width: 100%;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__circle {
        $size: 1.6rem;
        $animation-time: 1.6s;

        transform: scale(0);
        width: $size;
        height: $size;
        margin: 0 $size/6;
        background-color: $color-primary;
        border-radius: $size / 2;
        animation: new-loader-animation $animation-time ease-in-out infinite;

        &:nth-child(2) {
            animation-delay: $animation-time * 1/9;
        }
        &:nth-child(3) {
            animation-delay: $animation-time * 2/9;
        }
    }
}
