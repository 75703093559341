@import '../../../../../scss/variables';

._magazine-order-list-list {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 0 0 1rem 0;
        width: 100%;
        height: 4rem;
        text-transform: uppercase;
        font-weight: 500;
        @include ff-title();
        color: $color-primary;
        text-align: center;

        &-icon {
            width: 4rem;
        }

        &-id {
            width: 10%;
        }

        &-contractor {
            width: 20%;
        }

        &-destination {
            width: 17%;
        }

        &-created {
            width: 10%;
        }

        &-shipment-date {
            width: 10%;
        }

        &-status {
            width: 10%;
        }
        &-products {
            width: 10%;
        }
    }
}
