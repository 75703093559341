@import "../../../../../../scss/variables";

.select-date-modal__product-details {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &-name {
        font-size: 1.6rem;
    }

    &-ean {
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-grey-2;
    }
}
