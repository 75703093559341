@import '../../../../scss/_variables.scss';

.order-product-count-modal {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__product {
        display: flex;
        flex-direction: column;
        border: 1px solid $color-grey-4;

        &:not(:last-child) {
            border-bottom: none;
        }

        &-element {
            width: 100%;
            display: flex;
            align-items: center;
        }

        &-info {
            display: flex;
            flex-direction: column;
            flex: 1 0 20rem;
            padding: 1rem;
        }

        &-name {
            font-size: 1.2rem;
            font-weight: bold;
        }

        &-ean {
            font-size: 1.2rem;
            font-weight: bold;
            color: $color-grey-2;
        }

        &-category {
            flex: 0 0 100%;
        }

        &-include {
            display: flex;
            padding: 1rem;
            align-items: center;

            &-total {
                font-size: 1.4rem;
                font-weight: bold;
                padding: 0 1rem;
            }

            &-icon {
                @include ff-icon();
                width: 2.4rem;
                height: 2.4rem;
                border: 1px solid $color-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                border-radius: 0.3rem;
                color: $color-primary;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: $color-primary;
                    color: $color-primary-c;
                }
            }
        }
    }

    &__usage {
        &-element {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1rem 0;
            border-top: 1px solid $color-grey-4;
            background-color: $color-background;
        }

        &-contract {
            &-name {
                text-align: right;
                padding: 0.5rem 1rem;
                font-weight: bold;
            }

            &-quantity {
                display: flex;
                flex-direction: column;
                padding: 0 0.5rem;

                span {
                    font-size: 0.8rem;
                }

                .value {
                    height: 2.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.1rem;
                }
            }

            &-quantity-input {
                display: flex;
                flex-direction: column;
                padding: 0 1rem 0 0.5rem;

                input {
                    width: 6rem;
                    padding: 0.5rem;
                    text-align: center;
                    border: 1px solid $color-primary;
                    border-radius: 0.3rem;
                    height: 2.6rem;
                    background-color: white;
                    @include ff-text();
                    font-size: 1.1rem;
                }
            }
        }
    }
}
