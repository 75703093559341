.binding-modal__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.binding-modal__main-category-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $color-grey-3;

    &:not(:last-child) {
        border-bottom: none;
    }

    .binding-modal__main-category {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 4rem;

        .binding-modal__main-category-icon {
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-2;
            width: 3rem;
            text-align: center
        }

        .binding-modal__main-category-label {
            font-size: 1.4rem;

            .binding-modal__main-category-product-count {
                color: $color-grey-2;
                padding-left: 1rem;
            }
        }
    }
}

.binding-modal__child-category-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid $color-grey-3;

    .binding-modal__child-category {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 4rem;
        padding-left: 2rem;
        background-color: $color-background;

        .binding-modal__child-category-icon {
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-2;
            width: 3rem;
            text-align: center;
        }

        .binding-modal__child-category-label {
            font-size: 1.4rem;
            flex: 1 1 10rem;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .binding-modal__child-category-product-count {
                color: $color-grey-2;
                padding-left: 1rem;
            }
        }
    }
}

.binding-modal__product {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 4rem;
    background-color: $color-background-2;
    border-top: 1px solid $color-grey-3;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;

    .binding-modal__product-label {
        flex: 1 1 10rem;
        padding-right:1rem;
    }

    .binding-modal__product-number {
        flex: 0 0 7rem;
        text-align: right;
        padding-right: 1rem;
    }

    .binding-modal__product-binding {
        flex: 0 0 12rem;

        select {
            width: 100%;
        }
    }
}
