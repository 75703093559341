@import '../../scss/variables';
.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;

    h3 {
        width: 100%;
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-primary;
    }

    .modal__background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-background;
        opacity: 0.7;
    }

    .modal__container {
        position: relative;
        width: 80%;
        max-width: 500px;
        background-color: $color-elem-background;
        box-shadow: 0 0 0.6rem 0.3rem $color-elem-shadow;
        max-height: 90vh;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        border-radius: 0.3rem;
    }

    .modal__header {
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-bottom: 1px solid $color-grey-3;
    }

    .modal__title {
        flex: 1 0 200px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 2rem;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-primary;
    }

    .modal__close {
        flex: 0 0 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal__close-button {
        background: transparent;
        border: none;
        color: $color-primary;
        width: 5rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .material-icons {
            font-size: 2.6rem;
        }
    }

    .modal__content {
        width: 100%;
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 2rem;
        min-height: 10rem;
    }

    .modal__footer {
        min-height: 5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem 2rem 2rem;
        border-top: 1px solid $color-grey-3;

        .space-between {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}
