._view {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem 1rem 1rem;

    ._view__container {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        width: 100%;
        position: relative;

        &._view__container--medium {
            max-width: 600px;
        }

        &._view__container--bg {
            background-color: $color-elem-background;
            box-shadow: 0 0.2rem 1rem 0.2rem $color-elem-shadow;
            border-radius: 0.5rem;
            margin: 0 0 4rem 0;
            overflow: hidden;
            min-height: 200px;
        }
    }
}

._view__start__poster {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    padding: 0 0 0;

    ._view__start__poster__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 2rem;
        padding: 4rem 1rem;
        @include ff-title();
        text-align: center;
        font-weight: 700;
        color: $color-primary-c;
        background-color: $color-primary;
        text-transform: uppercase;

        span {
            font-weight: 400;
            font-size: 1.8rem;
        }
    }

    ._view__start__poster__subtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 1.8rem;
        padding: 1rem;
        text-transform: uppercase;
        font-weight: 700;
        color: $color-primary;
    }

    ._view__start__poster__images {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 1rem;

        img {
            width: 100%;

            &.img--logotypy-1 {
                width: 90%;
            }

            &.img--logotypy-2 {
                width: 70%;
            }
        }
    }
}

._view__start__title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2.5rem;
    @include ff-title();
    font-weight: 700;
    text-transform: uppercase;
    margin: 3rem 0 2rem;
    color: $color-primary;
}

._view__start__paragraph {
    padding: 2rem 2rem;
    font-size: 1.4rem;
    text-align: justify;

    &.paragraph--bigger {
        font-size: 1.6rem;
    }

    &.paragraph--bg {
        padding: 0rem 1rem;

        span {
            background-color: $color-grey-3;
            display: block;
            width: 100%;
            padding: 2rem 1rem;
        }
    }
}

._view__start__agenda {
    width: 100%;

    img {
        width: 100%;
    }
}

._view__start__container__title {
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem 1rem;
    text-transform: uppercase;
    color: $color-primary;
}

._view__start__location__gmaps__iframe {
    width: 100%;
    height: 300px;
    border: none;
    margin: 1rem 0 0 0;
}


._register {
    min-height: 200px;
}

._view__table {
    overflow-x: auto;
    padding: 2rem;
    display: block;
    
    table {
        border-collapse: collapse;
        font-size: 1.2rem;
        thead {
            tr {
                background-color: $color-primary;
                color: $color-primary-c;

                td {
                    padding: 0.5rem;
                }
            }
        }
        td{
            white-space: nowrap;
            padding: 0.5rem;
        }

        tr{
            &:nth-child(2n) {
                background-color: $color-grey-4;
            }
        }
    }
}