@import '../../../../scss/_variables.scss';

._contract-product-query-modal {
    &__products__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__products__empty {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        color: $color-grey-2;
        margin: 0 0 1rem 0;
    }

    &__product {
        &__wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 1rem;
            border: 1px solid $color-grey-4;
            border-radius: 0.3rem;
            margin-bottom: 1rem;
        }

        &__info {
            flex: 1 0 10rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-name {
                font-size: 1.2rem;
                color: $color-primary;
                font-weight: bold;
            }

            &-ean {
                font-size: 1.2rem;
                color: $color-grey-2;
                font-weight: bold;
                margin-top: 0.5rem;
            }
            &-category {
                font-size: 1.1rem;
                margin-top: 0.5rem;
            }
        }

        &__quantity {
            display: flex;
            align-items: center;

            &-table {
                font-size: 0.7rem;

                tr {
                    background-color: transparent;
                }

                td {
                    font-size: 0.9rem;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 0.2rem;

                    &:first-child {
                        text-align: right;
                    }
                }

                input {
                    width: 5rem;
                    height: 2rem;
                    border: 1px solid $color-primary;
                    border-radius: 0.3rem;
                    text-align: center;
                    @include ff-text();
                }

                thead {
                    tr {
                        color: $color-grey-1;
                        font-size: 0.7rem;

                        td {
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }

        &__remove-button {
            display: flex;
            align-items: center;
            margin-left: 0.5rem;

            button {
                width: 3rem;
                height: 3rem;
                display: flex;
                padding: 0;
                justify-content: center;
                align-items: center;
                border: 1px solid $color-primary;
                color: $color-primary;
                @include ff-icon();
                font-size: 2.4rem;
                cursor: pointer;
                transition: all 0.3s ease-out;
                border-radius: 0.3rem;
                background-color: transparent;
                margin: 0;

                &:hover {
                    background-color: $color-primary;
                    color: $color-primary-c;
                }
            }
        }
    }

    &__search-field {
        &__wrapper {
            width: 100%;
            display: flex;
            align-items: stretch;
        }

        &__input {
            flex: 1 0 10rem;
            height: 4rem;
            margin: 0;
            border: 1px solid $color-primary;
            border-radius: 0.5rem 0 0 0.5rem;
            padding: 0 1rem;
            font-size: 1.4rem;
            @include ff-text();
        }

        &__button-search {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10rem;
            border: 1px solid $color-primary;
            border-left: none;
            border-radius: 0 0.5rem 0.5rem 0;
            font-size: 1.2rem;
            @include ff-text();
            font-weight: bold;
            text-transform: uppercase;
            color: $color-primary;
            cursor: pointer;
            transition: all 0.3s ease-out;
            padding: 0;
            background-color: transparent;
                margin: 0;

            &:hover {
                background-color: $color-primary;
                color: $color-primary-c;
            }
        }

        &__button-clear {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 8rem;
            border: 1px solid $color-primary;
            border-radius: 0.5rem;
            @include ff-text();
            font-weight: bold;
            text-transform: uppercase;
            color: $color-primary;
            cursor: pointer;
            transition: all 0.3s ease-out;
            padding: 0;
            background-color: transparent;
                margin: 0 0 0 1rem;

            &:hover {
                background-color: $color-primary;
                color: $color-primary-c;
            }
        }
    }

    &__search-results {
        &__wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
        }

        &__empty {
            width: 100%;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 600;
            color: $color-grey-2;
            margin: 0 0 1rem 0;
        }
    }

    &__search-result {
        width: 100%;
        display: flex;
        border: 1px solid $color-grey-4;
        margin-bottom: 1rem;
        border-radius: 0.3rem;
        padding: 1rem;
        align-items: stretch;

        &__info {
            flex: 1 0 10rem;
            display: flex;
            flex-direction: column;

            &-name {
                font-size: 1.2rem;
                color: $color-primary;
                font-weight: bold;
            }

            &-ean {
                font-size: 1.2rem;
                color: $color-grey-2;
                font-weight: bold;
                margin-top: 0.5rem;
            }
            &-category {
                font-size: 1.1rem;
                margin-top: 0.5rem;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            margin-left: 5rem;
            min-width: 3rem;

            button {
                width: 3rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $color-primary;
                color: $color-primary;
                @include ff-icon();
                font-size: 2.4rem;
                cursor: pointer;
                transition: all 0.3s ease-out;
                border-radius: 0.3rem;
                padding: 0;
                background-color: transparent;
                margin: 0;

                &:hover {
                    background-color: $color-primary;
                    color: $color-primary-c;
                }
            }
        }
    }
}
