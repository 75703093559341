@import '../../../../../scss/variables';

._magazine-order-list-child {
    &__wrapper {
        width: 100%;
        display: flex;
        min-height: 4rem;
        padding: 0.5rem 0;
        border: 1px solid $color-grey-4;
        background-color: $color-grey-5;
        border-bottom: none;
        text-align: center;
        font-size: 1.2rem;
        align-items: center;
    }

    &__space {
        &-1 {
            width: 4rem;
        }
        &-2 {
            width: 20%;
        }
    }

    &__id {
        width: 10%;
        font-weight: bold;
    }

    &__magazine-location {
        width: 17%;
        text-align: left;
        padding: 0 0.5rem;
    }

    &__created {
        width: 10%;
    }

    &__shipment-date {
        width: 10%;
    }

    &__status {
        width: 10%;
        font-weight: bold;
        font-size: 1.1rem;
        color: $color-grey-2;
        text-transform: uppercase;
    }

    &__products {
        width: 10%;
        font-weight: bold;
        color: $color-grey-2;
    }

    &__buttons {
        flex: 1 0 5%;
        padding: 0 1rem 0 0;
        display: flex;
        justify-content: flex-end;
    }

}
