._button-light,
.button-light {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    background-color: transparent;
    border: 1px solid $color-primary;
    border-radius: 0.3rem;
    @include ff-title();
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 1.4rem;

    .material-icons {
        font-size: 2.5rem;
        margin: 0 0.5rem;
        @include ff-icon();
    }

    &.icon {
        @include ff-icon();
        text-transform: lowercase;
        padding: 0;
        width: 4rem;
        font-size: 2.4rem;
    }

    .icon {
        @include ff-icon();
        font-weight: normal;
        text-transform: lowercase;
        font-size: 2rem;
    }

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
    }

    &.small {
        height: 2.4rem;
        padding: 0 1rem;
        font-size: 1.2rem;

        &.icon {
            width: 2.4rem;
            font-size: 1.8rem;
            padding: 0;
        }
    }

    &.x-small {
        height: 2rem;
        padding: 0 1rem;
        font-size: 1rem;

        &.icon {
            width: 2rem;
            font-size: 1.4rem;
            padding: 0;
        }
    }

    &.no-border {
        border: none;

        &:hover {
            background-color: transparent;
            color: $color-primary;
        }
    }

    &.disabled {
        color: $color-grey-2;
        border-color: $color-grey-3;
        cursor: not-allowed;

        &:hover {
            background-color: transparent;
            color: $color-grey-2;
        }
    }
}
