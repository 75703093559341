@import '../../../../scss/variables';

._magazine-supply-product-modal {
    &__product {
        display: flex;
        width: 100%;
        // padding: 1rem;
        margin-bottom: 1rem;
        border: 1px solid $color-grey-3;
        border-radius: 0.3rem;
        flex-wrap: wrap;

        &-info {
            flex: 1 0 10rem;
            order: 1;
            padding: 1rem;

            &-name {
                font-size: 1.2rem;
                font-weight: bold;
                color: $color-primary;
            }

            &-ean {
                font-size: 1.2rem;
                font-weight: bold;
                color: $color-grey-2;
                padding: 0.3rem 0;
            }

            &-category {
                font-size: 1.1rem;
            }
        }

        &-quantities {
            order: 3;
            flex: 0 0 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            background-color: $color-grey-5;
            border-top: 1px solid $color-grey-4;
        }

        &-quantity {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0.5rem 1rem;
            flex-direction: row;
            align-items: cetner;
            width: 100%;

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey-4;
            }

            &-field {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 1rem;

                &-input {
                    width: 14rem;
                    padding: 0.2rem 0.5rem;
                    border: 1px solid $color-primary;
                    height: 2.4rem;
                    border-radius: 0.3rem;
                }

                &-label {
                    width: 14rem;
                    height: 1.5rem;
                    display: flex;
                    align-items: center;
                    color: $color-primary;
                    font-weight: 600;
                }

                &-error {
                    color: $color-red;
                    width: 14rem;
                }
            }

            &-remove {
                flex: 0 0 4rem;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                height: 100%;

                &-button {
                    @include ff-icon();
                    font-size: 1.6rem;
                    width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 0.3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include button-1();
                }
            }

            &-add {
                @include button-1;
                margin: 1rem;
                padding: 0.3rem 1rem;
                height: 2.4rem;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.1rem;
            }
        }

        &-remove {
            display: flex;
            justify-content: flex-end;
            padding: 1rem;
            align-items: flex-start;
            order: 2;

            &-button {
                width: 3rem;
                height: 3rem;
                @include ff-icon();
                border: 1px solid $color-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.3rem;
                font-size: 2rem;
                color: $color-primary;
                background-color: $color-primary-c;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: $color-primary;
                    color: $color-primary-c;
                }
            }
        }
    }
}
