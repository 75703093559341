._modal {
    width: 100%;


    &._modal--small {
        width: 300px;
        padding: 1rem;
        background-color: $color-elem-background;
        @include custom-shadow();
        border-radius: .3rem;
    }
}