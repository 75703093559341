@import "../../scss/variables";

.new-form-field-wrapper {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 2rem 0;
    }

    &__label {
        width: 100%;
        @include ff-title();
        color: $color-primary;
        font-weight: 500;
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    &__field {
        width: 100%;
        display: flex;
    }
}

.new-form-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
}

.form-field {
    &__text-field-input {
        width: 100%;
        margin: 0;
        border: 1px solid $color-grey-2;
        font-size: 1.6rem;
        padding: 1rem;
    }

    &__date-picker-input {
        width: 100%;
        border: 1px solid $color-grey-2;
        padding: 1rem;
        font-size: 1.6rem;
    }

    &__select {
        width: 100%;
        padding: 1rem;
        height: 4rem;
        border: 1px solid $color-grey-2;
        border-radius: 0;
        font-size: 1.6rem;
        background-color: white;
    }
}
