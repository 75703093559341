@mixin button-1 {
    border: 1px solid $color-primary;
    color: $color-primary;
    background-color: $color-primary-c;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
    }
}
