@import '../../../../scss/_variables.scss';
.contract-order-product {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__parent {
        display: flex;
        flex-direction: column;

        border: 1px solid $color-grey-4;

        &:not(:last-child) {
            border-bottom: none;
        }

        &-element {
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;
            padding: 1.5rem 1rem;
        }
    }

    &__arrow {
        @include ff-icon();
        font-size: 1.6rem;
        color: $color-grey-2;
        padding: 0 1rem 0 0;
    }

    &__product {
        &-name {
            font-size: 1.2rem;
            font-weight: 600;
            padding-right: 1rem;
        }
        &-ean {
            font-size: 1.2rem;
            font-weight: 600;
            color: $color-grey-2;
        }
        &-category {
            flex: 1 0 10rem;
            padding: 0 1rem 0 2rem;
        }
        &-quantity {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }

    &__child-element {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1.5rem 1rem 1.5rem 6rem;
        border-top: 1px solid $color-grey-4;
        background-color: $color-background;
    }

    &__contract {
        &-label {
            font-size: 1.2rem;
            padding-right: 5rem;
        }
        &-quantity {
            font-size: 1.1rem;
            padding-left: 0.5rem;

            &-used {
                font-size: 1.4rem;
                font-weight: bold;
            }
        }
    }
}
