@import "../../../../../scss/variables";

._magazine-state-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $color-grey-3;
    border-bottom: none;

    &__product {
        display: flex;
        flex-direction: column;

        &-content {
            display: flex;
            border-bottom: 1px solid $color-grey-3;
            align-items: center;
            font-size: 1.2rem;

            &-title {
                padding: 2rem;
                flex: 1 0 10rem;
            }

            &-ean {
                padding: 2rem;
                flex: 0 0 10rem;
                text-align: right;
            }

            &-count {
                padding: 2rem;
                flex: 0 0 10rem;
                text-align: center;
            }

            &-arrow {
                flex: 0 0 6rem;
                @include ff-icon;
                text-align: center;
                font-size: 2rem;
                color: $color-grey-2;
                padding: 1.5rem 0;
                cursor: pointer;
            }
        }

        &-counts {
            display: flex;
            flex-direction: column;
            background-color: $color-grey-5;
            border-right: 5px solid $color-grey-3;
            border-left: 5px solid $color-grey-3;
        }

        &-count {
            display: flex;
            border-bottom: 1px solid $color-grey-3;
            font-size: 1.2rem;
            padding-right: 5.5rem;

            &.no-padding {
                padding-right: 0;
            }

            &-date {
                padding: 2rem;
                flex: 1 0 10rem;
                text-align: right;
            }

            &-count {
                flex: 0 0 10rem;
                text-align: center;
                padding: 2rem 0;
            }

            &-arrow {
                flex: 0 0 5.5rem;
                padding-left: 0.5rem;
                @include ff-icon;
                text-align: center;
                font-size: 2rem;
                color: $color-grey-2;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}
