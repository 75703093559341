.file__preview-image {
    max-width: 50%;
    // max-height: 400px;
    width: 50%;
    position: relative;
}

.file-version-lang__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border: 1px solid $color-grey-3;

    &:not(:last-child) {
        border-bottom: none;
    }

    .file-version-lang__lang {
        flex: 0 0 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @include ff-text();
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-grey-2;
        border-right: 1px solid $color-grey-3;
    }

    .file-version-lang__file {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-right: 1px solid $color-grey-3;
        font-size: 1.4rem;
    }

    .file-version-lang__preview {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        border-right: 1px solid $color-grey-3;

        .file-version-lang__preview-label {
            padding: 1rem;
        }

        img {
            max-width: 300px;
            max-height: 300px;
        }
    }

    .file-version-lang__buttons {
        flex: 1 0 10rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;
    }
}
